





























































































































































































































































































































































































































































































































































































































import { Vue, Component, Emit } from "vue-property-decorator";
import {
  CustomInput,
  Dropdown,
  CustomCheckbox,
  MakeDuplicate,
} from "@components";

interface Company {
  municipality: string;
  province: string;
  region: string;
  country: string;
  businessNature: string;
}
interface AuthorizedRepresentative {
  serial: number;
  name: string;
  contact: string;
  email: string;
  position: string;
}

@Component({
  components: {
    CustomInput,
    Dropdown,
    CustomCheckbox,
    MakeDuplicate,
  },
})
export default class CompanyInformation extends Vue {
  componentId = 0;
  businessName = "";
  officePhoneNumber = "";
  accountNumber = "";
  address = "";
  businessYears = 0;
  grossSale = "";
  numberOfCustomers = "";
  companyRegistrationNumber = "";
  taxIDNumber = "";
  applicationServices = [];
  company: Company = {
    municipality: "",
    province: "",
    region: "",
    country: "",
    businessNature: "",
  };

  ownerStyle = "single";
  authorizedRepresentative: AuthorizedRepresentative[] = [
    { serial: 0, name: "", contact: "", email: "", position: "" },
  ];
  requestType = "new";

  get businessNatures() {
    return ["Nature Of Business1", "Nature Of Business2"];
  }
  get municipalities() {
    return ["municipality1", "municipality2"];
  }
  get province() {
    return ["province1", "province2"];
  }
  get region() {
    return ["region1", "region2"];
  }
  get country() {
    return ["country1", "country2"];
  }

  _addNewCmponent() {
    this.componentId = this.componentId + 1;
    this.authorizedRepresentative.unshift({
      serial: this.componentId,
      name: "",
      contact: "",
      email: "",
      position: "",
    });
  }
  _removeComponent(id: number) {
    const newRepresentatives = this.authorizedRepresentative.filter(
      (rep) => rep.serial != id
    );
    this.authorizedRepresentative = newRepresentatives;
  }

  // created() {
  // this.$emit("getValue", 1);
  // }
  @Emit("getValue")
  created() {
    return 1;
  }
}
